<template>
  <div class="container mt-5">
    <!--Section: Content-->
    <section class="formation">
      <!-- Section heading -->
      <h2 class="text-center font-weight-bold">Formations <br />accélérées</h2>
      <!-- Section description -->
      <div class="mx-auto w-responsive mb-5">
        <div class="container mb-5">
          <!--Section: Content-->
          <section class="text-center">
            <!-- Section heading -->
            <p
              class="text-center argument font-weight-bold dark-grey-text pb-2 my-4"
            >
              <strong>Taxi & vtc formations accélérées</strong>
            </p>
            <!-- Section description -->
            <p class="text-center w-responsive mx-auto mb-5">
              Découvrez nos <strong>formations accélérées VTC</strong> & Taxi et
              optez pour une formation adaptée à votre rythme, tout en
              conciliant vie privée et vie professionnelle.
            </p>

            <!-- Grid row -->
            <div class="row justify-content-center align-items-stretch">
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE-intense</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+237€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>4 samedi + 1 semaine</p>
                      </li>
                      <li>
                        <p>Semaine: 18h/21h30</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE-soir</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+237€ frais examen cma</p>
                    <ul>
                      <li>
                        <p class="mb-0">3 semaines</p>
                        <p class="mt-0 exe">examen blanc inclus</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Grid column -->
            <!-- Grid row -->
          </section>
          <!--Section: Content-->
        </div>
      </div>
      <div class="hr-d"></div>
      <CallToContact
        title="Contactez nous"
        subTitle="L'equipe pédagogique de l'institut taxi s'occupe de vos démarches administratives."
      />
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>

  <section class="container-fluid image-block">
    <div class="image-block__title">
      <h2 id="nightLesson">
        Formation accélérée vtc & taxi :<br />
        Une formation qui s'adapte à votre planning.
      </h2>
    </div>
    <div class="container-fluid image-block__left">
      <div class="col-lg-5 order-lg-2 slide-right">
        <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
          <img
            src="@/assets/pictures/sunshine.webp"
            alt="a-sunshine"
            class="img-fluid"
            loading="lazy"
          />
        </div>
      </div>
      <div class="col-lg-5 order-lg-1 slide-left">
        <p>
          Si vous souhaitez obtenir rapidement votre
          <strong>carte professionnelle de vtc et taxi</strong>, notre Institut
          propose des <strong>cours accélérés</strong> adaptés à vos besoins.
          Ces <strong>formations</strong> intensives vous permettent d'acquérir
          toutes les compétences requises en un temps réduit, sans compromis sur
          la qualité de l'apprentissage. De plus, pour ceux ayant des
          contraintes d'emploi du temps, nos <strong>cours du soir</strong> sont
          la solution idéale. Vous pourrez suivre la
          <strong>formation</strong> à des horaires flexibles, en conciliant
          votre travail ou vos autres engagements. Notre objectif est de rendre
          la <strong>formation</strong> accessible à tous, c'est pourquoi nous
          acceptons le <strong>CPF</strong> et offrons des facilités de paiement
          en 3 fois sans frais. De plus, notre centre de formation est
          accessible aux <strong>personnes à mobilité réduite </strong>(
          <strong>PMR)</strong>, avec des installations adaptées pour garantir
          un apprentissage inclusif. Rejoignez notre Institut Taxi et réalisez
          votre projet professionnel dans les meilleures conditions.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import CallToContact from "@/components/plugins/CallToContact.vue";
export default {
  name: "ServicesAccelere",
  components: { CallToContact },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", ".fall", ".pop");
    });
  },
};
</script>

<style lang="scss" scoped>
.formation {
  & h2 {
    text-align: center;
    background-color: $color-primary;
    color: $color-secondary;
    padding: 10px;
  }
  & .argument {
    font-family: "cunia";
    font-size: 1.3em;
  }
  .price-card {
    & .card {
      color: $color-text-light;
      background: #000000; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      display: flex;
      flex-direction: column; /* Assure un alignement vertical */
      height: 100%;
      min-height: 675px;

      & h3 {
        background-color: $color-primary;
        font-family: "quantum";
        color: $color-text-light;
        padding: 10px;
      }
      & .price {
        text-align: center;
        font-size: 2em;
        margin-bottom: 0px;
        & span {
          color: $color-secondary;
        }
      }
      & .priceBis {
        text-align: center;
        font-size: small;
        margin-bottom: 20px;
      }
      & .card-body {
        padding: 0px;
        flex-grow: 1;
        & ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          list-style-type: none;
          padding: 0px;
          & li,
          p {
            text-align: center;
          }
        }
      }
      & .card-footer {
        background-color: $color-secondary;
        padding: 0px;
        margin-top: auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
      }
    }
  }
}

.image-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 10px;
  text-align: center;
  @media screen and (min-width: 992px) {
    padding: 90px 10px;
  }
  &__title {
    color: $color-primary;
    margin-bottom: 80px;
    margin-top: 0px;
    & h2 {
      font-size: 1.3em;
      @media screen and (min-width: 992px) {
        font-size: 2em;
        margin-top: 10px;
      }
    }
  }
  &__head {
    & p {
      color: $color-text;
      text-align: center;
      margin: 0px 0px 50px;
      @media screen and (min-width: 992px) {
        font-size: 1.3em;
        padding: 0px 30px;
      }
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    & .point {
      border: 1px $color-secondary outset;
      border-radius: 50%;
      padding: 30px;
      font-size: 1.5em;
      margin: 30px 0px;
      color: $color-text-light;
      background-color: $color-primary;
      & span {
        color: $color-secondary;
      }
    }
  }

  &__aside {
    margin: 0px 0px;
    & p {
      text-align: left;
      margin-left: 0.3rem;
      color: $color-text-grey;
      font-size: 1.1em;
      margin-top: 18px;
      @media screen and (min-width: 992px) {
        margin-left: 0rem;
        margin-top: 0px;
        padding: 0rem 2.5rem;
      }
      & span:first-of-type {
        color: $color-primary;
      }
      & span:last-of-type {
        color: $color-secondary;
      }
    }
  }
}
.exe {
  color: $color-secondary;
  font-size: 0.9em;
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
.fall.active {
  @include fall-down(1s);
}
.pop.active {
  @include fade-in(1s);
}
</style>
