<template>
  <div class="container z-depth-1 my-5 p-lg-5">
    <!-- Section -->
    <section class="about-service">
      <h2 class="font-weight-bold text-center dark-grey-text pb-2">
        Conditions d'inscription à l'examen VTC ou Taxi
      </h2>
      <hr class="w-header my-4" />

      <div class="px-3 pt-5">
        <div class="goodToKnow">
          <div class="col-md-6 col-xl-4 slide-left">
            <h3 class="font-weight-normal">
              <a class="dark-grey-text" href="#">Prérequis</a>
            </h3>
            <p class="small grey-text">Les conditions</p>
          </div>

          <div class="col-md-6 slide-right">
            <p class="text-muted pb-2">
              Le candidat doit être détenteur d'une carte d'identité européenne
              valide ou avoir un titre de séjour lui permettant d'exercer en
              France.
            </p>
            <p class="text-muted pb-2">
              Le candidat doit être titulaire d'un permis B depuis au moins
              trois ans et non soumis à une période probatoire.
            </p>
          </div>
        </div>

        <div class="goodToKnow">
          <div class="col-md-6 col-xl-4 slide-left">
            <h3 class="font-weight-normal">
              <a class="dark-grey-text" href="#">Modalité</a>
            </h3>
            <p class="small grey-text">Le Délai</p>
          </div>

          <div class="col-md-6 slide-right">
            <p class="text-muted pb-2">
              Durée d'accès à la formation: 11 Jours minimum.
            </p>
          </div>
        </div>

        <div class="goodToKnow">
          <div class="col-md-6 col-xl-4 slide-left">
            <h3 class="font-weight-normal">
              <a class="dark-grey-text" href="#">À Savoir</a>
            </h3>
            <p class="small grey-text">Infos</p>
          </div>

          <div class="col-md-6 slide-right">
            <p class="text-muted pb-2">
              Vous devez être en capacité physique de devenir chauffeur
              professionnel.
            </p>
            <p class="text-muted pb-2">
              Le casier judiciaire, la visite médicale VTC et le PSC1 VTC ne
              sont pas demandés lors de l'inscription à l'examen VTC.
            </p>
            <p class="text-muted pb-2">
              L'institut Taxi c'est 93% de réussite aux examens et plus de 600
              avis sur google, rejoignez-nous !
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Section -->
  </div>
</template>

<script>
export default {
  name: "AboutService",
};
</script>

<style lang="scss" scoped>
.about-service {
  a {
    color: $color-primary;
  }
}
.goodToKnow {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 896px) {
    flex-direction: row;
    align-items: start;
    margin: 30px 0px;
  }
}
</style>
